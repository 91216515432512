import React, { useState, useEffect } from "react"
import Seo from "../components/Seo"
import Layout from "../components/Layout/Layout"
import PageTransitionContainer from "../components/PageTransitionContainer"
import { questions } from "../data/getstarted"
import { validEmail, normalizePhone } from "../utils/helper"
import "../styles/pages/getstarted.css"
import queryString from "query-string"
import { isFromCanada } from "../components/Home/DiscussForm"
// import { fetch } from 'whatwg-fetch'
import fetchPonyfill from "fetch-ponyfill"
const { fetch } = fetchPonyfill({})

const mapSlugToService = {
  "business-loans": "Business Loans",
  "debt-consolidation": "Debt Consolidation",
  "higher-education-loans": "Higher Education",
  "retired-individuals": "Retired Individuals",
  "tax-debt": "Tax Debt",
  "emergency-needs": "Emergency Needs",
  "home-renovation-loans": "Home Renovation",
  "self-employed": "Self Employed",
  other: "Other"
}

const GetStarted = ({ location }) => {
  const initVals = {
    "Loan Type": null,
    "Current Value": null,
    Mortgage: null,
    "Loan Amount": null,
    "Contact Preference": null
  }
  const [activeStep, setActiveStep] = useState(1)
  const [vals, setVals] = useState(initVals)

  const initData = { name: "", phone: "", email: "" }
  const [data, setData] = useState(initData) // set data
  const [honeyPot, setHoneyPot] = useState("") // spam prevention
  const [submitting, setSubmitting] = useState(false) // disable all fields
  const [error, setError] = useState({ ...initData, general: "" }) // disable all fields
  const [success, setSuccess] = useState(false) // disable all fields

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.service &&
      mapSlugToService[location.state.service] &&
      !vals["Loan Type"]
    ) {
      setVals((prev) => ({
        ...prev,
        "Loan Type": mapSlugToService[location.state.service]
      }))
      setActiveStep(2)
    }
  }, [location, setVals, setActiveStep, vals])

  const submitForm = () => {
    setSubmitting(true)
    if (!honeyPot) {
      // if its not spam
      if (
        !data.name ||
        !data.email ||
        !validEmail(data.email) ||
        !data.phone ||
        (data.phone && data.phone.length !== 12) ||
        (data.phone && !isFromCanada(data.phone))
      ) {
        setSubmitting(false)
        setError({
          ...error,
          name: !data.name ? "Name is required" : "",
          email: !data.email
            ? "Email is required"
            : !validEmail(data.email)
            ? "Email is not valid"
            : "",
          phone: !data.phone
            ? "Phone number is required"
            : data.phone && data.phone.length !== 12
            ? "Valid phone number is required"
            : data.phone && !isFromCanada(data.phone)
            ? "Valid Canadian phone number is required"
            : ""
        })
        return
      } else {
        // console.log("submittt", vals, data)
        setError({ ...initData, general: false })
        return fetch(
          "https://script.google.com/macros/s/AKfycbzime6hElt83Pqdp6wjilAWof2HHhXvwEQQZkts/exec",
          {
            method: "POST",
            body: queryString.stringify({ ...data, ...vals }),
            mode: "no-cors",
            headers: {
              // 'Access-Control-Allow-Headers': 'Content-Type',
              "Content-Type": "application/x-www-form-urlencoded"
              // 'X-Content-Type-Options': 'nosniff'
            }
          }
        )
          .then(() => {
            setSubmitting(false)
            setData(initData)
            setSuccess(true)
          })
          .catch((err) => {
            // console.log("err", err)
            setError({
              ...error,
              general:
                "Something went wrong with the form submission. Try again"
            })
            setSubmitting(false)
          })
      }
    }
  }
  return (
    <PageTransitionContainer>
      <Layout location={location} page="getstarted" breakout={true}>
        <Seo title="Get Started | RateLock" location={location} />
        <section id="getstarted">
          <div className="container">
            <div className="form-container">
              <div className="steps">
                {[1, 2, 3, 4, 5].map((step) => (
                  <div
                    role="button"
                    tabIndex={0}
                    key={step}
                    onClick={() =>
                      !success && step <= activeStep ? setActiveStep(step) : {}
                    }
                    onKeyUp={() =>
                      !success && step <= activeStep ? setActiveStep(step) : {}
                    }
                    className={`step ${
                      activeStep === step
                        ? "curr active"
                        : activeStep > step
                        ? "active"
                        : ""
                    }`}
                  >
                    {step * 20}%
                  </div>
                ))}
              </div>
              <div className="form-container-inner">
                {success ? (
                  <div className="form form-success-message">
                    Thank you for your enquiry. Your message has been sent
                    successfully. We'll reach out to you shortly.
                  </div>
                ) : (
                  <div className="form">
                    {Object.keys(questions).map((q) => {
                      return activeStep.toString() === q.toString() ? (
                        <fieldset key={q}>
                          <label>{questions[q].label}</label>
                          <div
                            className={
                              activeStep.toString() === "1"
                                ? "buttons-flex"
                                : ""
                            }
                          >
                            {questions[q].buttons.map((button) => (
                              <button
                                key={button}
                                onClick={() => {
                                  setVals({
                                    ...vals,
                                    [questions[q].name]: button
                                  })
                                  setActiveStep(activeStep + 1)
                                }}
                              >
                                {button}
                              </button>
                            ))}
                          </div>
                        </fieldset>
                      ) : null
                    })}
                    {activeStep === 5 && (
                      <form>
                        <fieldset>
                          <p>
                            Please fill in your details and we'll reach out to
                            you as soon as possible
                          </p>
                        </fieldset>
                        <input
                          id="honeypot"
                          type="text"
                          name="honeypot"
                          value={honeyPot}
                          onChange={(e) => setHoneyPot(e.target.value)}
                        />
                        <fieldset>
                          <input
                            type="text"
                            name="Name"
                            placeholder="Enter your name"
                            value={data["name"]}
                            disabled={submitting}
                            onChange={(e) =>
                              setData({ ...data, name: e.target.value })
                            }
                          />
                          {error && error["name"] && (
                            <p className="error">{error["name"]}</p>
                          )}
                        </fieldset>
                        <fieldset>
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={data["email"]}
                            disabled={submitting}
                            onChange={(e) =>
                              setData({ ...data, email: e.target.value })
                            }
                          />
                          {error && error["email"] && (
                            <p className="error">{error["email"]}</p>
                          )}
                        </fieldset>
                        <fieldset>
                          <input
                            type="text"
                            name="phone"
                            placeholder="Phone"
                            value={data.phone}
                            disabled={submitting}
                            onChange={(e) =>
                              setData({
                                ...data,
                                phone: normalizePhone(e.target.value)
                              })
                            }
                          />
                          {error && error["phone"] && (
                            <p className="error">{error["phone"]}</p>
                          )}
                        </fieldset>

                        {/* <fieldset>
                                            <input type="checkbox" defaultChecked />
                                            <label>Send me helpful news and account info.</label>
                                        </fieldset> */}
                        <button
                          type="button"
                          className="btn"
                          onClick={submitForm}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </div>
                )}
              </div>
              {activeStep > 1 && !success && (
                <button
                  type="button"
                  className="back-button"
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Back
                </button>
              )}
            </div>
          </div>
        </section>
      </Layout>
    </PageTransitionContainer>
  )
}

export default GetStarted
