export let questions = {
  1: {
    label: "What kind of loan are you looking for?",
    name: "Loan Type",
    buttons: [
      "Business Loans",
      "Debt Consolidation",
      "Emergency Needs",
      "Higher Education",
      "Home Renovation",
      "Retired Individuals",
      "Tax Debt",
      "Other"
    ]
  },
  2: {
    label: "What is the current value of your property?",
    name: "Current Value",
    buttons: [
      "Less Than 500K",
      "500K - 750K",
      "750K - 1M",
      "1M - 2M",
      "2M - 4M",
      "Greater Than 4M"
    ]
  },
  3: {
    label: "What is the current balance on the existing mortgage",
    name: "Mortgage",
    buttons: [
      "Less than 50K",
      "50K - 100K",
      "100 - 250K",
      "250K - 500K",
      "500K - 1 Million",
      "Greater Than 1 Million"
    ]
  },
  4: {
    label: "What is the requested loan amount?",
    name: "Loan Amount",
    buttons: [
      "Less Than 35K",
      "35K - 75K",
      "75K - 150K",
      "150K - 250K",
      "250K - 500K",
      "Greater Than 500K"
    ]
  }
  // 5: {
  //   label: "What is the best way to contact you?",
  //   name: "Contact Preference",
  //   buttons: ["Email", "Phone"]
  // }
}
